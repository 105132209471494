import { Box, Flex, Heading, HStack, List, ListItem, useToast, VStack } from '@chakra-ui/react'
import { JsonViewer } from '@textea/json-viewer'
import { MitekResponse, MitekToSharedType } from '../Mitek'
import { OCRtoJson } from './OCRtoJson'
import { OverlappingProperties } from '../OverlappingProperties'
import { useDropzone } from 'react-dropzone'
import { useEffect, useState } from 'react'
import { getAuthenticationStatistics } from '../OCR'
import { whiteMostly } from '../JSONTheme'

interface IProps {
  type: "Mitek" | "OCR"
  onFileSelect: (file: any) => void;
  selectedFile?: string;
}

// Generate random guid
const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  let res = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  return res;
}



export const DragDrop = (props: IProps) => {

  const [files, setFiles] = useState<any[]>([]);
  const [activeFile, setActiveFile] = useState<any>();
  const toast = useToast();

  useEffect(() => {
    if (props.selectedFile === undefined) return;
    let file = files.find((file: any) => file.path === props.selectedFile);
    if (file) {
      setActiveFile(file);
      props.onFileSelect(file);
    }
  }, [props.selectedFile])


  function onDrop(acceptedFiles: File[]) {
    const filesPromise = acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      return new Promise((resolve, reject) => {
        reader.onload = function (evt) {
          try {
            let jsonFile = JSON.parse(evt.target?.result as string);
            if (!jsonFile.hasOwnProperty('id')) {
              jsonFile.id = guid();
              jsonFile.path = file.name;
            }
            resolve(jsonFile);
          } catch (e) {
            reject();
          }
        }
      });
    });
    Promise.all(filesPromise).then(jsonFiles => {
      let mres: OverlappingProperties[] = [];
      jsonFiles.forEach((file: any) => {
        mres.push(MitekToSharedType(file));
      });
      setFiles(mres);
    });
  }

  useEffect(() => {
    if (files.length === 0) return;
    toastStats(files);
  }, [files])

  const toastStats = (files: OverlappingProperties[]) => {
    console.log("toasting stats");
    
    var map = getAuthenticationStatistics(files);
    // Create an object of res so we can use it in the toast
    var res = Object.fromEntries(map);
    console.log(res);
    
    toast({
      title: "OCR File(s) loaded",
      containerStyle: { minWidth: "500px" },
      description: <JsonViewer theme={whiteMostly} displayDataTypes={false} value={res} />,
      duration: 90000,
      isClosable: true,
    })
  }

  const handleFileSelect = (file: any) => {
    setActiveFile(file);
    props.onFileSelect(file);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Flex h="100%" w="100%" p="6" flexDir="column" gap="4">
      <VStack>
        <Box {...getRootProps()} bgColor="linkedin.50" borderRadius="15" p="4" flex={props.type === "OCR" ? "0.5" : "1"} >
          <input {...getInputProps()} />
          <Heading as="h4" size="lg">Drag {props.type} JSON files here, or click to select</Heading>
        </Box>
        {
          props.type === "OCR" && <Box bgColor="linkedin.50" flex={props.type === "OCR" ? "0.5" : "1"}>
            <OCRtoJson setFiles={setFiles} />
          </Box>
        }
        {
          props.type === "Mitek" && <Box bgColor="linkedin.50" flex={props.type === "Mitek" ? "0.5" : "1"}>
            <Heading as="h4" size="lg">Mitek</Heading>
          </Box>
        }

      </VStack>

      <List height="200px" overflowY="scroll" bgColor="orange.100" borderRadius="14" p="4" spacing="2">
        {files.map((file, index) => (
          <ListItem cursor="not-allowed" key={`${file?.path}-r`} bgColor={activeFile?.id == file?.id ? "green.200" : "green.50"} borderRadius="4" p="2" fontWeight={activeFile?.id == file.id ? "bold" : "medium"}>{index + ' - ' + file?.path}</ListItem>
        ))}
      </List>
      <Box className="left-section" bgColor="red.50" borderRadius="14" h="100%" p="6" flex="1">
        {activeFile && <JsonViewer displayDataTypes={false} value={activeFile} />}
      </Box>
    </Flex>
  );
}
