import { isEqual } from 'lodash'

interface JSONDiff {
  [key: string]: any;
}

export function compareJSON(obj1: any, obj2: any, pick: "left" | "right"): JSONDiff {
    let difference: JSONDiff = {};
    for (let key in obj1) {
        if (typeof obj1[key] === "string") {
            obj1[key] = obj1[key].toLowerCase();
        }
        if (typeof obj2[key] === "string") {
            obj2[key] = obj2[key].toLowerCase();
        }
    }
    for (let key in obj1) {
        if (isEqual(obj1[key], obj2[key])) {
            continue;
        } else if (obj2[key] == undefined) {
            if (pick === "left") {
                difference[key] = obj1[key];
            }
        } else if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
            let diff = compareJSON(obj1[key], obj2[key], pick);
            if (Object.keys(diff).length > 0) {
                difference[key] = diff;
            }
        } else {
            if (pick === "left") {
                difference[key] = obj1[key];
            } else {
                difference[key] = obj2[key];
            }
        }
    }
    for (let key in obj2) {
        if (obj1[key] == undefined) {
            if (pick === "right") {
                difference[key] = obj2[key];
            }
        }
    }
    return difference;
}