import { MitekResponse } from './Mitek';
import { OverlappingProperties } from './OverlappingProperties';
import { StringIncludesArr } from './Utils/StringIncludesArr';

export interface CardInfo {
  key: string;
  value: string;
  accuracy: string;
}

export interface OCRResponse {
  status: string;
  message: string;
  code: string;
  CardInfo: CardInfo[];
}

export interface OCRRoot {
  OCR: OCRResponse;
  DFA: any;
  "File Name": string;
  id: string;
  path: string
}

export interface ParsedOcrResponse { 
  status: string;
  message: string;
  code: string;
  CardInfo: { [key: string]: {value: string, accuracy: string} };
}

export const parseOcrResponse = (response: OCRResponse): ParsedOcrResponse => {
  const parsedResponse = {
    status: response.status,
    message: response.message,
    code: response.code,
    CardInfo: response.CardInfo?.reduce((acc, curr) => {
      acc[curr.key] = { value: curr.value, accuracy: curr.accuracy };
      return acc;
    }, {} as ParsedOcrResponse['CardInfo'])
  };
  
  return parsedResponse;
};

export const OcrToSharedType = (root: OCRRoot) => {
  let e = parseOcrResponse(root.OCR);
  
  let result: OverlappingProperties = {
    path: root['File Name'],
    wasApprovedByOCRProvider: 'OCR Labs: '+ LowRiskHighRisk(root?.DFA?.dfa_overall_result ?? 'N/A') ,
    expiry_date: e.CardInfo?.expiry_date?.value ?? '',
    given_name: e.CardInfo?.given_name?.value ?? '',
    full_name: e.CardInfo?.full_name?.value ?? '',
    country: e.CardInfo?.country?.value ?? '',
    country_code: e.CardInfo?.country_code?.value ?? '',
    years_since_issue: e.CardInfo?.years_since_issue?.value ?? 'N/A Can be calculated',
    age_at_issue: e.CardInfo?.age_at_issue?.value ?? 'N/A Can be calculated',
    age: e.CardInfo?.age?.value ?? 'N/A Can be calculated',
    last_name: e.CardInfo?.last_name?.value ?? '',
    id_number: e.CardInfo?.id_number?.value ?? '',
    authority: e.CardInfo?.authority?.value ?? '',
    birth_place: e.CardInfo?.birth_place?.value ?? '',
    mrz_full: e.CardInfo?.mrz_full?.value ?? '',
    height: e.CardInfo?.height?.value ?? '',
    document_number: e.CardInfo?.document_number?.value ?? '',
    mrz_birth_date: e.CardInfo?.mrz_birth_date?.value ?? '',
    mrz_class: e.CardInfo?.mrz_class?.value ?? '',
    mrz_expiry_date: e.CardInfo?.mrz_expiry_date?.value ?? '',
    birth_date: e.CardInfo?.birth_date?.value ?? '',
    first_name: e.CardInfo?.first_name?.value ?? '',
    document_type: e.CardInfo?.document_type?.value ?? '',
    gender: e.CardInfo?.gender?.value ?? '',
    issue_date: e.CardInfo?.issue_date?.value ?? '',
    mrz_gender: e.CardInfo?.mrz_gender?.value ?? '',
    mrz_last_name: e.CardInfo?.mrz_last_name?.value ?? '',
    mrz_nationality_code: e.CardInfo?.mrz_nationality_code?.value ?? '',
    nationality: e.CardInfo?.nationality?.value ?? '',
  };
  if(root.OCR.CardInfo && root.OCR.CardInfo.length > 0) {    
    root.OCR.CardInfo.forEach((element) => {
      if(StringIncludesArr(element.key.toLowerCase(), ["address", "house", "street", "city", "state", "zip", "country", "country_code"])) {
        result.address = {...result.address, [element.key]: element.value};
      }
    });
  }

  return result;
}


const LowRiskHighRisk = (risk: string) => {
  if(risk === 'Low Risk') {
    return true;
  }
  if(risk === 'High Risk') {
    return false;
  }
  return risk;
}


export function getAuthenticationStatistics(responses: OverlappingProperties[]): Map<string, { total: number, authenticated: number }> {
  const stats = new Map<string, { total: number, authenticated: number }>();

  for (const response of responses) {
    const type = response.document_type;
    const authenticated = response.wasApprovedByOCRProvider == "OCR Labs: true" ? true : false;

    const typeStats = stats.get(type) || { total: 0, authenticated: 0 };
    typeStats.total += 1;
    if (authenticated) {
      typeStats.authenticated += 1;
    }

    stats.set(type, typeStats);
  }

  return stats;
}
