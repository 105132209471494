import { OverlappingProperties } from './OverlappingProperties';

interface DossierMetaData {
  DossierId: string;
  ProcessingStatus: string;
  CustomerReferenceId: string;
  CreatedDateTime: string;
  Version: string;
}

interface Evidence {
  Type: string;
  Images: Image[];
  ExtractedData?: {
    Name: {
      GivenNames: string;
      Surname: string;
      FullName: string;
      DynamicProperties: any;
    },
    Address: {
      AddressLine1: any;
      AddressLine2: any;
      AddressLine3: any;
      AddressLine4: any;
      Country: any;
      City: any;
      StateProvince: any;
      PostalCode: any;
      DynamicProperties: any;
    },
    DocumentNumber: string;
    DateOfExpiry: string;
    DateOfBirth: string;
    DateOfIssue: string;
    DynamicProperties: any;
  }
}

interface Image {
  ImageId: string;
  CustomerReferenceId: any;
  ExtractedData: {
    Name: {
      GivenNames: string;
      Surname: string;
      FullName: string;
      DynamicProperties: any;
    },
    Address: {
      AddressLine1: any;
      Country: any;
      City: any;
      StateProvince: any;
      PostalCode: any;
      DynamicProperties: any;
    },
    DocumentNumber: string;
    DateOfExpiry: string;
    DateOfBirth: string;
    DateOfIssue: string;
    DynamicProperties: any;
  },
  ProcessingStatus: string;
  Classification: {
    Mdsid: string;
    ImageType: string;
  },
  DerivedImages: {
    CroppedPortrait: any;
    CroppedSignature: any;
    CroppedDocument: any;
  },
  ProcessingReasons: any;
}

export interface MitekResponse {
  DossierMetaData: DossierMetaData;
  Evidence: Evidence[];
  Findings: any;
}

export const MitekToSharedType = (e: MitekResponse) => {
  console.log(e);
  
  const hasEvidence = e.Evidence !== undefined;
  let result: OverlappingProperties = {
    dossierMetaData: e?.DossierMetaData?.DossierId ?? '',
    wasApprovedByOCRProvider: e.Findings === undefined ? '' : 'Mitek: ' + (e?.Findings?.Authenticated ?? 'N/A'),
    path: (e as any).path ?? '',
    expiry_date: e.Evidence === undefined ? ''  : (e.Evidence[0]?.ExtractedData?.DateOfExpiry ?? ''),
    given_name: e.Evidence === undefined ? ''  : (e.Evidence[0]?.ExtractedData?.Name?.GivenNames ?? ''),
    full_name: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.Name?.FullName ?? ''),
    country: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.Address?.Country ?? ''),
    country_code: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.Address?.Country ?? ''),
    years_since_issue: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DateOfIssue ?? 'N/A but can be calculated'),
    age_at_issue: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DateOfIssue ?? 'N/A but can be calculated'),
    age: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DateOfBirth ?? ''),
    last_name: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.Name?.Surname ?? ''),
    id_number: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DocumentNumber ?? ''),
    authority: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DynamicProperties?.IssuingAuthority ?? ''),
    birth_place: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.Address?.Country ?? ''),
    mrz_full: e.Evidence === undefined ? '' : ((e.Evidence[0].Images[0]?.ExtractedData?.DynamicProperties.mrzLine1 ?? 'N/A') + (e.Evidence[0].Images[0]?.ExtractedData?.DynamicProperties?.mrzLine2 ?? 'N/A')),
    height: 'N/A',
    document_number: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DocumentNumber ?? ''),
    mrz_birth_date: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DateOfBirth ?? ''),
    mrz_class: 'N/A',
    mrz_expiry_date: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DateOfExpiry ?? ''),
    birth_date: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DateOfBirth ?? ''),
    first_name: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.Name?.GivenNames ?? ''),
    document_type: e.Evidence === undefined ? '' : (e?.Evidence[0]?.Images[0]?.Classification?.ImageType ?? ''),
    gender: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DynamicProperties.Sex ?? ''),
    issue_date: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DateOfIssue ?? ''),
    mrz_gender: e.Evidence === undefined ? '' : (e.Evidence[0]?.Images[0].ExtractedData?.DynamicProperties?.mrzSex ?? ''),
    mrz_last_name: e.Evidence === undefined ? '' : (e.Evidence[0].Images[0]?.ExtractedData?.Name?.Surname ?? ''),
    mrz_nationality_code: e.Evidence === undefined ? '' : (e.Evidence[0].Images[0]?.ExtractedData?.DynamicProperties.mrzNationality ?? ''),
    nationality: e.Evidence === undefined ? '' : (e.Evidence[0]?.ExtractedData?.DynamicProperties?.Nationality ?? '')
  };

  result.address = e.Evidence === undefined ? '' : extractAddressData(e);

  return result;
}

function extractAddressData(e: MitekResponse) {
  const addressData = {};
  const dataSources = [
    {
      source: 'ExtractedData',
      data: e.Evidence && e.Evidence[0] && e.Evidence[0].ExtractedData
    },
    {
      source: 'Images[0].ExtractedData',
      data: e.Evidence && e.Evidence[0] && e.Evidence[0].Images && e.Evidence[0].Images[0] && e.Evidence[0].Images[0].ExtractedData
    },
    {
      source: 'Images[1].ExtractedData',
      data: e.Evidence && e.Evidence[0] && e.Evidence[0].Images && e.Evidence[0].Images[1] && e.Evidence[0].Images[1].ExtractedData
    }
  ];

  const addressKeys = [
    "address",
    "house",
    "street",
    "city",
    "state",
    "zip",
    "country",
    "country_code",
    "postal",
    "post",
    "code",
  ];

  dataSources.forEach(({ source, data }) => {
    if (!data) return;

    (addressData as any)[source] = {};

    Object.entries(data).forEach(([key, value]) => {
      const lowerCaseKey = key.toLowerCase();
      if (addressKeys.includes(lowerCaseKey) && value !== null) {
        (addressData as any)[source][lowerCaseKey] = value;
      }
    });

    (addressData as any)[source] = removeEmpty((addressData as any)[source]);
  });

  return addressData;
}


function removeEmpty(obj: object): object {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
}

// export const getMitekAddress = (e: MitekResponse, object: string[], res: any) => {
  
//   let curr = object.pop();
//   let currObject = {};
//   if(curr === undefined) {
//     return address;
//   }
//   if(curr === "DynamicProperties") {
//     currObject = e.Evidence[0].ExtractedData?.DynamicProperties ?? {};
//   }

//   if(curr === "Img0") {
//     currObject = e.Evidence[0].Images[0].ExtractedData ?? {};
//   }

//   if(curr === "Img1") {
//     currObject = e.Evidence[0].Images[1].ExtractedData ?? {};
//   }
  
//   // Check if any of e.Evidence[0].Images[0].ExtractedData?.DynamicProperties is null
//   if(e.Evidence[0].Images[0].ExtractedData?.DynamicProperties === null) {
//     return address;
//   }
  
//   let dynamicPropertiesKeys = Object.keys(e.Evidence[0].Images[0].ExtractedData?.DynamicProperties ?? {});
//   dynamicPropertiesKeys.forEach((key: string) => {    
//     if(StringIncludesArr(key.toLowerCase(), ["address", "house", "street", "city", "state", "zip", "country", "country_code", "postal", "post", "code"])) {
//       // 
//       if((address as any)[key] !== undefined) {
//         (address as any)[key] = { '1': (address as any)[key], '2': e.Evidence[0].Images[1].ExtractedData?.DynamicProperties[key] };
//       } else {
//         address = {...address, [key]: e.Evidence[0].Images[0].ExtractedData?.DynamicProperties[key]};
//       }
//     }
//   });

//   return getMitekAddress(e, object);
// }

export function getAuthenticationStatistics(responses: MitekResponse[]): Map<string, { total: number, authenticated: number }> {
  const stats = new Map<string, { total: number, authenticated: number }>();

  for (const response of responses) {
    const image = response.Evidence[0].Images[0];
    if(image?.Classification?.ImageType ?? 'Unknown' == 'Unknown') {
      if(response?.Findings?.Authenticated) {
        console.log(response);
      }
    }
    const type = image?.Classification?.ImageType ?? 'Unknown';
    const authenticated = response?.Findings?.Authenticated ?? false;

    const typeStats = stats.get(type) || { total: 0, authenticated: 0 };
    typeStats.total += 1;
    if (authenticated) {
      typeStats.authenticated += 1;
    }

    stats.set(type, typeStats);
  }

  return stats;
}
