import Base64Utils from './Components/Base64Utils'
import logo from './logo.svg'
import React, { useMemo } from 'react'
import {   Box,Button,Center,ChakraProvider,Container,extendBaseTheme,extendTheme,Flex,Grid,GridItem,Heading,Modal,ModalBody,ModalCloseButton,ModalContent,ModalFooter,ModalHeader,ModalOverlay,Text,useDisclosure} from '@chakra-ui/react'
import { compareJSON } from './Utils/JSONCompare'
import { DragDrop } from './Components/DragDrop'
import { JsonViewer } from '@textea/json-viewer'
import './App.css'
import { DragDropMitek } from './Components/DragDropMitek'


function App() {
  const [leftFile, setLeftFile] = React.useState<any>();
  const [rightFile, setRightFile] = React.useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const differenceL = useMemo(() => {
    if (!leftFile || !rightFile) return null
    return compareJSON(leftFile, rightFile, "left")
  }, [leftFile, rightFile])

  const differenceR = useMemo(() => {
    if (!leftFile || !rightFile) return null
    return compareJSON(leftFile, rightFile, "right")
  }, [leftFile, rightFile])

  const theme = extendTheme({
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    }
  });

  return (
    <ChakraProvider theme={theme}>
      <Button onClick={onOpen} position="absolute" bottom="0" zIndex="2" left="calc(50% - 100px)" w="200px" mb="4">Open base64 utils</Button>
      <Grid templateColumns='repeat(3, 1fr)' gap={6} h="100vh" bg='blue.100'>
        <GridItem w='100%' h='100%' bg='blackAlpha.50'>
          <DragDropMitek type="Mitek" onFileSelect={(e) => setLeftFile(e)} />
        </GridItem>
        <GridItem w='100%' h='100%' bg='whiteAlpha.50' p="4">
          <Text textAlign="center" mb="6">
            <b>Properties of both L and R json file are compared and the difference is shown below.</b>
            <br /><i>Comparison is case insensitive</i>
          </Text>
          <Flex>
            <Box flex="0.5">
              {leftFile && rightFile && <JsonViewer displayDataTypes={false} value={differenceL} />}
            </Box>
            <Box flex="0.5">
              {leftFile && rightFile && <JsonViewer displayDataTypes={false} value={differenceR} />}
            </Box>
          </Flex>

        </GridItem>
        <GridItem w='100%' h='100%' bg='cyan.50'>
          <DragDrop type="OCR" onFileSelect={(e) => setRightFile(e)} selectedFile={leftFile?.path} />
        </GridItem>
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bgColor="white">
          <Center>
            <Container w="xl">
              <ModalHeader><Heading as="h1">Base 64 Utils</Heading></ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Base64Utils />
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </Container>
          </Center>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default App;
