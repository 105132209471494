import { NamedColorspace } from "@textea/json-viewer";

export const whiteMostly: NamedColorspace = {
    scheme: 'Ocean',
    author: 'Chris Kempson (http://chriskempson.com)',
    base00: '#000',
    base01: '#ffff',
    base02: '#ffff',
    base03: '#ffff',
    base04: '#ffff',
    base05: '#ffff',
    base06: '#ffff',
    base07: '#eff1f5',
    base08: '#bf616a',
    base09: '#d08770',
    base0A: '#ebcb8b',
    base0B: '#a3be8c',
    base0C: '#96b5b4',
    base0D: '#8fa1b3',
    base0E: '#b48ead',
    base0F: '#ab7967'
  }