import { Box, Button, Flex, Heading, HStack, Input, List, ListItem, useToast, VStack } from '@chakra-ui/react'
import { JsonViewer } from '@textea/json-viewer'
import { getAuthenticationStatistics, MitekResponse, MitekToSharedType } from '../Mitek'
import { OCRtoJson } from './OCRtoJson'
import { OverlappingProperties } from '../OverlappingProperties'
import { useDropzone } from 'react-dropzone'
import { useEffect, useState } from 'react'
import { whiteMostly } from '../JSONTheme'

interface IProps {
  type: "Mitek" | "OCR"
  onFileSelect: (file: any) => void;
  selectedFile?: string;
}

// Generate random guid
const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  let res = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  return res;
}


export const DragDropMitek = (props: IProps) => {

  const [userEdited, setUserEdited] = useState(false);

  const [files, setFiles] = useState<any[]>([]);
  const [activeFile, setActiveFile] = useState<any>();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFiles, setFilteredFiles] = useState<any[]>([]);
  const toast = useToast();

  useEffect(() => {
    if (props.selectedFile === undefined) return;
    let file = files.find((file: any) => file.path === props.selectedFile);
    if (file) {
      setActiveFile(file);
      props.onFileSelect({ ...(userEdited ? file.userEdited : file.original), path: file.path });
    }
  }, [props.selectedFile])

  useEffect(() => {
    setFilteredFiles(
      files.filter((file: any) =>
        file.path.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, files]);

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  function onDrop(acceptedFiles: File[]) {
    const filesPromise = acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      return new Promise((resolve, reject) => {
        reader.onload = function (evt) {
          try {

            let jsonFile = JSON.parse(evt.target?.result as string);
            if (!jsonFile.hasOwnProperty('id')) {
              jsonFile.id = guid();
              jsonFile.path = file.name;
            }
            jsonFile.path = file.name;
            resolve(jsonFile);
          } catch (e) {
            reject();
          }
        }
      });
    });
    Promise.all(filesPromise).then(jsonFiles => {
      let mres: OverlappingProperties[] = [];
      // Invalid data flow will not work, obviously..
      try {
        toastStats(jsonFiles as MitekResponse[]);
      } catch (e) {
        console.log(e);
      }

      jsonFiles.forEach((file: any) => {
        mres.push(MitekToSharedType(file));
      });
      // setFiles(mres);
      setFiles(Object.values(groupedFiles(mres)));
    });
  }

  const toastStats = (files: MitekResponse[]) => {
    var map = getAuthenticationStatistics(files);
    // Create an object of res so we can use it in the toast
    var res = Object.fromEntries(map);
    toast({
      title: "Mitek File(s) loaded",
      containerStyle: { minWidth: "500px" },
      description: <JsonViewer theme={whiteMostly} displayDataTypes={false} value={res} />,
      duration: 90000,
      isClosable: true,
    })
  }

  const handleFileSelect = (file: any) => {
    setActiveFile(file);
    props.onFileSelect({ ...(userEdited ? file.userEdited : file.original), path: file.path });
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  // Group all files that start with the same but end on -original.json and -user-edited.json and create an array of objects with { original: originalFile, userEdited: userEditedFile}
  const groupedFiles = (files: OverlappingProperties[]) => {
    let groupedFiles: any = {};
    files.forEach((file: OverlappingProperties) => {
      let fileName = file.path.split('-')[0];
      if (!groupedFiles[fileName]) {
        groupedFiles[fileName] = { original: file, userEdited: undefined, path: file.path.split("-")[0].split(".")[0] };
      } else {
        groupedFiles[fileName].userEdited = file;
      }
    });
    return groupedFiles;
  }

  return (
    <Flex h="100%" w="100%" p="6" flexDir="column" gap="4">
      <VStack>
        <Box {...getRootProps()} bgColor="linkedin.50" borderRadius="15" p="4" flex={props.type === "OCR" ? "0.5" : "1"} >
          <input {...getInputProps()} />
          <Heading as="h4" size="lg">Drag {props.type} JSON files here, or click to select</Heading>
        </Box>
        {
          props.type === "OCR" && <Box bgColor="linkedin.50" flex={props.type === "OCR" ? "0.5" : "1"}>
            <OCRtoJson setFiles={setFiles} />
          </Box>
        }
        <Input placeholder="Search CTXID" value={searchTerm} onChange={handleSearch} />
      </VStack>

      <List height="200px" overflowY="scroll" bgColor="orange.100" borderRadius="14" p="4" spacing="2">
        {filteredFiles.map((file, index) => {
          return (
            <ListItem key={file?.path} onClick={() => handleFileSelect(file)} bgColor={activeFile?.id == file?.id ? "green.200" : "green.50"} borderRadius="4" p="2" fontWeight={activeFile?.id == file.id ? "bold" : "medium"}>{index + ' - ' + file?.path}</ListItem>
          )
        })}

      </List>
      <Box className="left-section" bgColor="red.50" borderRadius="14" h="100%" p="6" flex="1">
        {activeFile && <JsonViewer displayDataTypes={false} value={userEdited ? activeFile.userEdited : activeFile.original} />}
      </Box>
      <Button bgColor={userEdited ? "orange.100" : "red.100"} onClick={() => setUserEdited(!userEdited)}> View {userEdited ? "Raw Mitek Response" : "User Edited Response"} </Button>
    </Flex>
  );
}
