import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import { OcrToSharedType } from '../OCR'
import { OverlappingProperties } from '../OverlappingProperties'
import { parse } from 'best-effort-json-parser'
import { read, utils } from 'xlsx'
const jsonFix = require('json-fixer')

interface IProps {
  setFiles: (files: any) => void;
}

export const OCRtoJson = (props: IProps) => {
  const [jsonData, setJsonData] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = utils.sheet_to_json(worksheet) as any[];

      jsonData.forEach((element, index) => {
        console.log(index);
        
        // console.log(element["OCR"].replace('"value": ""', '"value": "Geen"').replace(/""/g, '"').replace(/"{/g, '{').replace(/}"/g, '}').replace(/\n/g, "").replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, "").replace(/\\/g, ''));
        // console.log(element["DFA"]);
            
        element.path = element["File Name"];
        element.id = element["File Name"];
        try {
          element["OCR"] = parse(element["OCR"].replace("“", '"').replace('"value": ""', '"value": "Geen"').replace(/""/g, '"').replace(/"{/g, '{').replace(/}"/g, '}').replace(/\n/g, "").replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, "").replace(/\\/g, ''));  
        } catch (error) {
          element["OCR"] = {"message": "This is not a valid JSON string."}
        }
        try {
          element["DFA"] = JSON.parse(element["DFA"].replace("“", '"').replace('"message": "",', '"message": "Geen",').replace('""', '"').replace('"{', '{').replace('}"', '}'));  
        } catch (error) {
          element["DFA"] = {"message": "This is not a valid JSON string."}
        }
      });

      setJsonData(jsonData);
      let res: OverlappingProperties[] = [];
      jsonData.forEach(element => {
        res.push(OcrToSharedType(element));
      });
      props.setFiles(res);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Box bgColor="linkedin.100">
      <input type="file" onChange={handleFileChange} />
      {/* {jsonData && <pre>{JSON.stringify(jsonData, null, 2)}</pre>} */}
    </Box>
  );
}