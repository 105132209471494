import React, { useState } from 'react'
import { Box, Button, FormLabel, Input } from '@chakra-ui/react'

interface Props { }

const Base64Utils: React.FC<Props> = () => {
  const [base64Input, setBase64Input] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);

  const convertAndDownload = () => {
    if (!base64Input) {
      return;
    }
    let base64Image = base64Input;
    if (base64Image.slice(0, 50).includes(";base64,")) {
      base64Image = base64Image.split(";base64,").pop()!;
    }
    var byteArray = new Uint8Array(atob(base64Image).split("").map(function (c) {
      return c.charCodeAt(0);
    }));
    const blob = new Blob([byteArray], { type: "image/png" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "image.png";
    a.click();
  };

  const convertToBase64 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    setImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Input(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const copyToClipboard = () => {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(base64Input)

    /* Alert the copied text */
    alert("Copied the text: " + base64Input);
  }

  return (
    <Box bgColor="whiteAlpha.100">
      <form>
        <FormLabel htmlFor="file-input">Select image file:</FormLabel><br />
        <Input type="file" id="file-input" onChange={convertToBase64} accept=".png" />
        <br /><br />
        <FormLabel htmlFor="base64-input">Enter base64 encoded image:</FormLabel><br />
        <Input type="text" id="base64-input" onChange={(e) => setBase64Input(e.target.value)} value={base64Input} />
        <br /><br />
        <Button onClick={convertAndDownload} disabled={!base64Input}>Download Image</Button>
      </form>
      <Box>
        <br />
        <p>Base64 Preview:</p>
        <Box id="base64-preview" style={{ backgroundColor: "lightgray", width: "100%", padding: "10px" }}>{base64Input.slice(0, 200)}...</Box>
        <Button onClick={copyToClipboard}>Copy</Button>
      </Box>
    </Box>
  );
}

export default Base64Utils;